import { observable, action, computed } from "mobx";
import Tour from "../models/Tour";
import TourSection from "../models/TourSection";
import TourStop from "../models/TourStop";
import Popup, { popupPosition } from "../models/Popup";
import { SourceSteamTour, CantileverTour } from './TourData';

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};



class TourStore {

  @observable tours: Tour[] = [SourceSteamTour as Tour, CantileverTour]

  @observable
  tour: Tour = new Tour();
  @action selectTour(tour: Tour) {
    this.section = tour.sections[0];

    this.tour = tour;

    this.popup = this.tour.sections[1].stops[0].popups[0]

  }

  @observable
  section: TourSection = new TourSection();
  @action selectSection(sectionTitle: string) {
    let section = this.tour.sections.find(s => s.title == sectionTitle);
    if (section) {
      this.section = section;
      /*this.stop = section.stops[0];
      this.popup = this.stop.popups[0]*/
    }
  }

  @observable
  stop: TourStop = new TourStop();
  @action selectStop(stopTitle: string) {
    let stop = this.section.stops.find(s => s.title == stopTitle);
    this.stop = stop ? stop : this.stop;
  }
  @action setPopupIdxForStop(stop: TourStop, popup: Popup) {
    this.tour.sections = this.tour.sections.map(s => {
      s.stops = s.stops.map(stop => {
        stop.popups = stop.popups.map((p, i) => {
          if (p.title == popup.title) {
            stop.selectedPopupIdx = i;
          }
          return p;
        });
        return stop;
      });

      return s;
    });
  }

  @observable
  popup = new Popup();
  @action
  selectPopup(popUp: Popup) {
    this.popup = popUp;
  }
  @action
  unSelectPopup() {
    this.popup = new Popup({globalIdx: -1});
  }


  private _updateStops(stop: TourStop) {
    console.log(stop.causeImageIdx, stop.effectImageIdx)
    let tour = new Tour({ ...this.tour }) as Tour;
    tour.sections = this.tour.sections.map(s => {
      s.stops = s.stops.map(stp => {
        if (stp.title == stop.title) {
          stp.effectImageIdx = stop.effectImageIdx;
          stp.causeImageIdx = stp.causeImageIdx;
        }
        return stp;
      })
      return s
    })
    this.tour = tour;
  }
  @action async changeStopIdx(stop: TourStop, forwardOrBack: 1 | -1) {
    stop.causeImageIdx += forwardOrBack;
    this._updateStops(stop);

    await sleep(500);

    stop.effectImageIdx += forwardOrBack;
    this._updateStops(stop);
    console.log(this.tour)
  }

  @computed get popups(): Popup[] {
    let pus: Popup[] = [];

    this.tour.sections.forEach(s => {
      s.stops.forEach(stop => {
        pus = pus.concat(stop.popups);
      });
    });

    return pus;
  }
}

export default new TourStore();
