import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Tour from './Tour'
import Nav from './Nav'
import Heroes from './Heroes'
import './App.scss';
import { observer } from 'mobx-react';
import TourStore from './stores/TourStore';

@observer
class App extends Component {
  private _scrollInterval: any;
  private _isScrolling: boolean = false;
  private _scrollListener: (targ: HTMLElement) => void = () => { };

  componentDidMount() {

    this._scrollListener = (element: HTMLElement) => {
      let offset = element.offsetTop;
      if (window.pageYOffset > offset) {
        element.classList.add("sticky")
      } else {
        element.classList.remove("sticky")
      }
    }

    let navRef = this.refs["nav"];
    if (navRef) {
      let element = ReactDOM.findDOMNode(navRef) as HTMLElement;
      this._scrollInterval = setInterval(() => {
        if (this._isScrolling) {
          this._scrollListener(element);
          this._isScrolling = false;
        }
      }, 10);
    }

    window.onscroll = () => {
      this._isScrolling = true;
    }

  }

  componentWillMount() {
    clearInterval(this._scrollInterval)
  }

  render() {

    const { tour } = TourStore;

    return (
      <div className="App">

        <Heroes />

        <main>
          <Tour tour={tour}/>
        </main>
      </div>
    );
  }
}

export default App;
