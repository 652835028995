import BaseModel from "./BaseModel";

export enum popupPosition{
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left', 
    RIGHT = 'right'
}

export default class Popup extends BaseModel{

    constructor(data?: Partial<Popup>){
        super(data);
        if(data){
            this.setUp(data)
        }
    }

    title: string = "";
    content: string = "";
    target: [number, number] = [0,0];  
    positions: popupPosition[] = [popupPosition.LEFT];
    get position() {
        return this.positions.join(" ")
    }

    globalIdx: number = 0;
    stopIdx: number = 0;
    clientNeed:string = ""

}