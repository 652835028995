import Tour from "../models/Tour";
import TourSection from "../models/TourSection";
import TourStop from "../models/TourStop";
import Popup, { popupPosition } from "../models/Popup";

let ssIterator = 0;
export const SourceSteamTour: Tour = new Tour({
  title: "Source Stream",
  tagLine: `<h1>THE SOURCE STREAM EXPERIENCE</h1>
  <p>A Management Development Simulation</p>
  `,
  tagImage: "https://test.sapienevolution.com/img/wonderwall.png",
  sections: [
    new TourSection({
      title: "Source Stream",
      isIntro: true,
      introContent: `<div>
          <p>
            A global technology company needed to advance the skills of its thousands of globally distributed new managers. This company partnered with Sapien to create a deeply customized business simulation to address these needs. The simulation provides new managers a structured opportunity to practice these critical management behaviors:
          </p>
          <ul>
            <li>Hone critical communication skills</li>
            <li>Understand and effectively work across the enterprise</li>
            <li>Collaborate with others</li>
            <li>Develop ability to give feedback</li>
          </ul>
          <p>
            One imperative was that the company’s internal facilitators easily run the simulation themselves. This simulation therefore includes a facilitator and administrator layer.
          </p>
        </div>`,
      stops: [
        new TourStop({
          title: "0",
          popups: []
        })
      ]
    }),
    new TourSection({
      title: "SUMMARY",
      stops: [
        new TourStop({
          title: "1",
          image: "https://test.sapienevolution.com/img/dontgochasing.png",
          popups: [
            new Popup({
              title: "Overview",
              positions: [popupPosition.TOP],
              content: `The sim is set in a fictional global technology company, Source Stream. Participants are placed in teams of 3-5, and will stay with this team for all 5 rounds of the sim.
                  \n\n
                  Each round is set in a different functional area: People, Finance, Engineering, Sales, Customer Service. In each round a different person plays the role of manager, so that everyone on the team has the opportunity to play this role at least once.
                  `,
              target: [51, 80],
              clientNeed:
                `We set the simulation in an environment that mirrors our client's unique strategic and cultural context.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "PARTICIPANT EXPERIENCE",
      stops: [
        new TourStop({
          title: "2",
          image: "https://test.sapienevolution.com/img/start_page.png",
          popups: [
            new Popup({
              title: "SOURCE STREAM TEAMS",
              positions: [popupPosition.TOP, popupPosition.LEFT],
              content:
                "Participants log in, and discover they are either an associate or the manager of the team.",
              target: [31, 70],
              clientNeed:
                "Participants must do more than learn or observe managerial behaviors - they must practice them. Each person on the team needs to actively participate as the manager. No one is able to take a back seat."
            })
          ]
        }),
        new TourStop({
          title: "3",
          image: "https://test.sapienevolution.com/img/round1.png",
          popups: [
            new Popup({
              title: "SIMULATION INTERFACE",
              positions: [popupPosition.RIGHT],
              content:
                "The streamlined simulation interface provides a user-friendly experience for participants",
              target: [7, 47],
              clientNeed:
                "Managers must work in time and resource constrained environments, which is brought to life by similar simulation parameters. Once logged in, participants must be able to quickly and easily access the necessary information with little or no assistance from the sim facilitator. "
            }),
            new Popup({
              title: "THE CHALLENGE",
              positions: [popupPosition.LEFT, popupPosition.TOP],
              content: `Teams work together to make decisions through five rounds. The manager and associates are presented with different information and points of view in each round. Asymmetry of information pushes participants to advocate for a position and clearly communicate their point of view.`,
              target: [19.2, 85],
              clientNeed:
                "Managers must typically consume and digest a lot of information quickly. The information sources can be internal or external, from senior leaders or junior associates, from personal experience or subject matter experts. All members of a team need to come to their own conclusions, and then be able to listen to others and potentially pivot according to what they hear."
            }),
            new Popup({
              title: "INBOX",
              positions: [popupPosition.LEFT, popupPosition.TOP],
              content: `Managers are often barraged by an overload of information from various sources. They must learn to prioritize and decide which information sources to pay attention to and when.`,
              target: [33.6, 81],
              clientNeed:
                "In each round, managers and associates have access to an inbox, where additional information is delivered in varying formats (email, voicemail, video transcripts). Managers and associates often receive different inbox items."
            })
          ]

          //
        }),
        new TourStop({
          title: "4",
          image: "https://test.sapienevolution.com/img/Recording_2.gif",
          popups: [
            new Popup({
              title: "DECISIONS",
              positions: [popupPosition.TOP],
              content: `In each round, teams are tasked with making a critical decision that will impact Source Stream’s future - and their team score. Only the manager can enter team decisions, so the associates must work together to convince the manager of their position (which may or may not be the correct one).`,
              target: [42, 74],
              clientNeed: `Making decisions as a team can be complicated - particularly when the stakes are high and the answer is unclear. Managers must take guidance from their associates, but ultimately it is up to them to make the final call.`
            })
          ]
        }),
        new TourStop({
          title: "5",
          image: "https://test.sapienevolution.com/img/poster.jpg",
          video: "https://test.sapienevolution.com/img/V5_2_with_slate.mp4",
          popups: [
            new Popup({
              title: "VIDEOS FROM SOURCE STREAM EXECUTIVES",
              positions: [popupPosition.TOP],
              content: `In each round, teams view a video from a fictional Source Stream executive. This element intensifies the simulation experience, and raises the stakes for the decisions.`,
              target: [77, 11],
              clientNeed: `In any organization, there are competing priorities, represented by competing voices. Different functions are sometimes at cross-purposes and the managers must use their judgement to decide which voice to align with in a given scenario.`
            })
          ]
        }),
        new TourStop({
          title: "6",
          image: "https://test.sapienevolution.com/img/scores.png",
          popups: [
            new Popup({
              title: "RESULTS",
              positions: [popupPosition.TOP],
              content: `After all teams enter their decisions, they will receive their team scores. The results page includes the team score, the ranking of all teams for the round, and ranking of all teams for all decisions made so far. The results page also displays an explanation of the correct answer for each decision.`,
              target: [21, 58],
              clientNeed: `Teams need to understand the ramifications of their decisions. They must understand how they are performing as a team, but also how they compare to other teams who are engaged with the same types of decisions. Teams can benefit from learning from each other, even in potentially competitive situations.`
            })
          ]
        }),
        new TourStop({
          title: "7",
          image: "https://test.sapienevolution.com/img/ratings.gif",
          popups: [
            new Popup({
              title: "INDIVIDUAL SCORING",
              positions: [popupPosition.TOP],
              content: `After teams receive their scores, participants must rate each other based on different criteria each round. Managers must give each associate a score, and associates must score their manager.`,
              target: [33.5, 82],
              clientNeed: `Employees at all levels must develop the ability to critically - yet thoughtfully - assess their colleagues against given criteria. In this simulation, managers are unable to give the same score to more than one individual. This forced ranking adds to the significance of the scoring decision.`
            })
          ]
        }),
        new TourStop({
          title: "8",
          image: "https://test.sapienevolution.com/img/ratings.png",
          popups: [
            new Popup({
              title: "FEEDBACK CONVERSATIONS",
              positions: [popupPosition.TOP],
              content: `Once participants receive their individual score, they are provided a framework for giving the feedback that drove the scores they gave one another. This activity is an opportunity to practice feedback skills that are critical in a managerial role.`,
              target: [19, 50],
              clientNeed: `One of the most critical skills a new manager needs to master is to effectively and clearly deliver feedback. These conversations are rarely easy, so it takes practice to overcome any associated anxiety or hesitation to deliver feedback that will ultimately positively impact performance.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "FACILITATOR LAYER",
      stops: [
        new TourStop({
          title: "9",
          image: "",
          causeImages: [
            "https://test.sapienevolution.com/img/fac-1.png",
            "https://test.sapienevolution.com/img/fac-2.png"
          ],
          causeCaptions: [
            `<h1>The factilitator slide presentation, shown here, allows direct control of player experience. Try it out.</h1>`,
            `<h1>The factilitator slide presentation, shown here, allows direct control of player experience. Try it out.</h1>`
          ],
          effectImages: [
            "https://test.sapienevolution.com/img/player-1.png",
            "https://test.sapienevolution.com/img/player-2.png"
          ],
          effectCaptions: [
            `<h1>Players experience seemless gameplay. Facilitators only have to advance their slide decks, and players are taken to the next step.</h1>`,
            `<h1>Players experience seemless gameplay. Facilitators only have to advance their slide decks, and players are taken to the next step.</h1>`
          ],

          popups: [
            new Popup({
              title: "SLIDE DRIVEN",
              positions: [popupPosition.TOP],
              content: `The user-friendly facilitator layer allows facilitators to focus on content, not technology. Facilitators simply advance the facilitation slide deck to advance the state of the sim for participants.`,
              target: [50, 50]
            })
          ]
        }),
        new TourStop({
          title: "10",
          image: "https://test.sapienevolution.com/img/fac-scores.png",
          popups: [
            new Popup({
              title: "AUTOMATICALLY-GENERATED RESULTS",
              positions: [popupPosition.RIGHT],
              content: `Results are automatically generated and populated in the facilitator slide deck for participants to view in advance of seeing the scores on their devices. This functionality allows facilitators to quickly debrief results before participants are distracted in the process of reviewing their team scores.`,
              target: [30, 63]
            })
          ]
        }),
        new TourStop({
          title: "11",
          image: "https://test.sapienevolution.com/img/fac-dash.png",
          popups: [
            new Popup({
              title: "FACILITATOR DASHBOARD",
              positions: [popupPosition.TOP],
              content: `The dashboard gives facilitators a view of which participants have successfully submitted their decisions and feedback rankings.`,
              target: [42, 50]
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "ADMINISTRATOR TOOLS",
      stops: [
        new TourStop({
          title: "10",
          image: "https://test.sapienevolution.com/img/admin.png",
          popups: [
            new Popup({
              title: "AUTOMATICALLY-GENERATED RESULTS",
              positions: [popupPosition.RIGHT],
              content: `Results are automatically generated and populated in the facilitator slide deck for participants to view in advance of seeing the scores on their devices. This functionality allows facilitators to quickly debrief results before participants are distracted in the process of reviewing their team scores.`,
              target: [30, 63]
            })
          ]
        })
      ]
    })
    
  ].map(s => {
    s.stops.map(stop => {
      stop.popups = stop.popups.map(p => {
        p.globalIdx = ssIterator;
        ssIterator++;
        return p;
      });
      return stop;
    });
    return s;
  })
});

let cantIterateHer = 0;
export const CantileverTour: Tour = new Tour({
  title: "Cantilever",
  tagLine: `<h1>THE CANTILEVER EXPERIENCE</h1>
  <p>A Strategic Transformation Simulation</p>
  `,
  tagImage: "https://test.sapienevolution.com/img/bridge.jpg",
  sections: [
    new TourSection({
      title: "Cantilever",
      isIntro: true,
      introContent: `<div>
        <p>
          As a global technology company embarked upon a strategic transformation, they needed to rapidly enlist thousands of their managers to lead the way. The move -- from profiting from one-off, large hardware deals to generating ongoing software-as-a-service revenue -- required the managers to quickly grasp new business and cultural concepts as they worked to develop a common vocabulary and shared commitment to the transformation.
        </p>
        <p>
          The company partnered with Sapien to create a custom simulation that makes the need for the transformation inescapable and helps managers build the skills and learn the tools needed to drive the change. Learning outcomes included:
        </p>
        <ul>
          <li>Accept and embrace large-scale business transformation</li>
          <li>Leverage qualitative and quantitative data to make strategic decisions</li>
          <li>Affect culture change within spheres of influence</li>
          <li>Identify and capitalize on strengths within a diverse team</li>
        </ul>
        <p>
          Given the complexity of the data sets and a large participant to facilitator ratio, it was imperative that the sim incorporate advanced technology (AI, sophisticated modeling) to give participants a clear and seamless user experience without extensive facilitator intervention.
        </p>
      </div>`,
      stops: [
        new TourStop({
          title: "0",
          popups: []
        })
      ]
    }),
    new TourSection({
      title: "SUMMARY",
      stops: [
        new TourStop({
          title: "1",
          image: "https://test.sapienevolution.com/img/cant-welcome.png",
          popups: [
            new Popup({
              title: "Overview",
              positions: [popupPosition.BOTTOM],
              content: `The sim is set in a fictional global technology company, Cantilever. This company is embarking on a large-scale business transformation. Participants are placed in teams of 5-8, and will stay with this team for all 5 rounds of the sim. Participant take on the role of Executive Leadership Team members, making strategic and financial decisions over the course of 4 simulated years.`,
              target: [38, 30],
              clientNeed: `Organizations undergoing strategic transformation often require meaningful cultural change as well. This client understood the importance of trying on and practicing new mindsets and behaviors as a means to achieving lasting momentum and cementing the associated changes. The sim took a future-orientation, which pushed participants to think past current state and consider alternate realities in years to come.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "PARTICIPANT EXPERIENCE",
      stops: [
        new TourStop({
          title: "2",
          image: "https://test.sapienevolution.com/img/team_structure.png",
          popups: [
            new Popup({
              title: "TEAM STRUCTURE",
              positions: [popupPosition.RIGHT],
              content:
                "Teams are made of of individuals across the organization, ideally from different functions and geographies. Teams determine how they will allocate the data and identify a process for making decisions.",
              target: [50, 53],
              clientNeed: `Slipping into functional silos is particularly easy in very large organizations. When undergoing an organizational transformation, individuals and teams must connect across their silos and experience the company from an enterprise-wide view. This simulation was an opportunity for participants to work with a diverse team of people from their company, and to see beyond their functional "comfort zones".`
            })
          ]
        }),
        new TourStop({
          title: "3",
          image: "https://test.sapienevolution.com/img/panels.png",
          popups: [
            new Popup({
              title: "SIMULATION INTERFACE",
              positions: [popupPosition.RIGHT],
              content:
                "In each round, participants are given access to multiple data sources: financial reports, competitor reports, market overview and performance analysis. This information is presented in a clear and simple interface, and the information is easily accessible.",
              target: [31, 50],
              clientNeed:
                "Leaders have access to multiple sources of information, opinions and data on an ongoing basis. Organizations often choose complicated and complex technology applications to allow access to data -  but this approach  makes shifting through information even more burdensome. The technology should be straightforward, the decisions should be made by humans working together."
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "WORLD CREATION",
      stops: [
        new TourStop({
          title: "4",
          image: "https://test.sapienevolution.com/img/immersive.png",
          popups: [
            new Popup({
              title: "IMMERSIVE CONTENT",
              positions: [popupPosition.TOP],
              content: `The sim is set in a “near world” to the client, starting a year from current state and moving through four subsequent years. The context, storyline, characters and real-time performance analysis are thoughtfully created to mirror the client organization and bring the sim to life. This rich content envelops participants in an environment that is familiar, and also offers an opportunity to learn and practice without real world repercussions.`,
              target: [70, 73],
              clientNeed: `Leaders operate in the real world with competing priorities and ambiguous futures. In this reality, the stakes are oftentimes high, the amount of information can be overwhelming, and the “right” decision is unclear. When undergoing transformation, it is helpful for leaders to viscerally experience the changes they must lead their teams through. Sapien undertook a deep discovery process to uncover the nuances, characters and organizational DNA to shape the simulation storyline and context.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "MAKING STRATEGY HUMAN",
      stops: [
        new TourStop({
          title: "5",
          image: "https://test.sapienevolution.com/img/strat-belief.png",
          popups: [
            new Popup({
              title: "STRATEGIC BELIEFS",
              positions: [popupPosition.BOTTOM],
              content: `At the onset of the simulation, participants are introduced to six Cantilever executives from different functions - and with very distinct points of view about the future of the organization. The first decision teams must make is to choose the character that best represents the team’s position. The character they choose will set the team on a strategic and financial path for the remaining four decisions.`,
              target: [90, 35],
              clientNeed: `The client’s strategic transformation grew from their executives’ beliefs about the way in which the world will change, and how the company can best adapt to those changes. Understanding the beliefs behind the strategy helps managers provide specific context when advocating for the transformation to their employees and customers. Senior leaders consistently hear different points of view regarding the future direction of an organization. It is rarely the case that one point of view is unequivocally right or wrong. Leaders must learn to distill the important points from each of these voices and lean into a strategic path.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "ARTIFICIAL INTELLIGENCE",
      stops: [
        new TourStop({
          title: "6",
          image: "https://test.sapienevolution.com/img/ai.png",
          popups: [
            new Popup({
              title: "PERFORMANCE ANALYSIS",
              positions: [popupPosition.LEFT],
              content: `Vanessa Lopez, the Cantilever ELT Chief of Staff, is coded to pull from previous decisions, and teams receive specific and customized feedback based on their decisions. Teams receive the same amount and specificity of feedback, as their experience is not dictated by access to a facilitator.`,
              target: [17, 67],
              clientNeed: `Leaders are often given advice about their past and future decisions from peers and executives, and they must decide what to do with these recommendations. Given the large class size and 30:1 participant to facilitator ratio, it was necessary that the sim utilize AI to explain results to teams. This method allowed to scale the experience without the cost of additional facilitators.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "ADVANCED MODELING",
      stops: [
        new TourStop({
          title: "7",
          image: "https://test.sapienevolution.com/img/quant.png",
          popups: [
            new Popup({
              title: "QUANTITATIVE ANALYSIS",
              positions: [popupPosition.RIGHT],
              content: `Robust and complex coding brings numbers to life and sets different teams on usually widely varied tracks. The results received in each round are truly dynamic and the model includes no branching (if/then) code.`,
              target: [7, 47],
              clientNeed: `Leaders must consume vast amounts of quantitative data derived from past decisions in order to make their next move. This data is dynamic, leaders must be able to spot patterns and dependencies over time. When an organization pivots its core focus, leaders must be particularly adept at pinpointing which decisions ultimately have the greatest impact on the health of the company. Sapien built its models using the client’s financial statements as a starting point, and projecting the data into the future based on scenarios associated with every possible decision.`
            })
          ]
        }),
        new TourStop({
          title: "8",
          image: "https://test.sapienevolution.com/img/cant-slide.png",
          popups: [
            new Popup({
              title: "QUANTITATIVE ANALYSIS",
              positions: [popupPosition.BOTTOM],
              content: `Transformations often require bold actions. Leaders must put a stake in the ground, and choose the areas of focus they believe will move the organizational needle. Once leaders have all the data and chosen a path forward, they must work together and with their teams to determine on what scale they will make each decision. Will it be a radical pivot or merely an almost imperceptible shift?`,
              target: [80, 47],
              clientNeed: `Transformations often require bold actions. Leaders must put a stake in the ground, and choose the areas of focus they believe will move the organizational needle. Once leaders have all the data and chosen a path forward, they must work together and with their teams to determine on what scale they will make each decision. Will it be a radical pivot or merely an almost imperceptible shift?Participants enter their financial decisions via sliders. They must choose how much to invest in each of five divisions: Edge, Infrastructure, Applications, Services, and Corporate. Sliders provide a visual means of seeing and almost feeling the relative magnitude of each decision.`
            })
          ]
        })
      ]
    }),
    new TourSection({
      title: "STRATEGY TRANSLATION",
      stops: [
        new TourStop({
          title: "9",
          image: "https://test.sapienevolution.com/img/room.png",
          popups: [
            new Popup({
              title: "PRESENTATIONS",
              positions: [popupPosition.RIGHT],
              content: `Team presentations are integrated into the simulation flow. Teams must give the rationale behind their initial decision, justify subsequent decisions, explain their results, and articulate a plan for the next three years.`,
              target: [7, 47],
              clientNeed: `Business transformations require senior leaders to not only relay strategy, but translate and make it relatable to other stakeholders. They must explain the reasons behind their decisions, and clearly lay out a path forward.`
            })
          ]
        })
      ]
    })
  ].map(s => {
    s.stops.map(stop => {
      stop.popups = stop.popups.map(p => {
        p.globalIdx = cantIterateHer;
        cantIterateHer++;
        return p;
      });
      return stop;
    });
    return s;
  })
});
