import React, { Component } from "react";
import { observer } from "mobx-react";
import TourStore from "./stores/TourStore";
import Tour from "./models/Tour";

export default class Heroes extends Component {
    render() {
        const { tours, selectTour } = TourStore;
        const _selectTour = selectTour.bind(TourStore);

        const onSelectTour = (tour: Tour) => {
            _selectTour(tour);
            setTimeout(() => {
                let element = document.querySelector("main");
                if (element) {
                    let nav = document.querySelector("nav");
                    let minus = nav ? nav.clientHeight + 30 : 90;
                    let target =
                        element.getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top -
                        minus;

                    let newUrl = window.location.protocol + "//" + window.location.host + "#main";
                    window.location.href = newUrl;
                }
            }, 50);
        };

        return (
            <section className="heroes">
                {tours.map(t => (
                    <div
                        className="tour-hero"
                        key={t.title}
                        style={{ backgroundImage: `url(${t.tagImage})` }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: t.tagLine }}
                        ></span>
                        <a
                            onClick={e => {
                                e.preventDefault();
                                onSelectTour(t);
                            }}
                            className="big-button"
                        >
                        TAKE THE TOUR
                </a>
                    </div>
                ))}
            </section>
        );
    }

}
