import React, { Component } from "react";
import ReactDOM from "react-dom";
import TourSection from "./models/TourSection";
import Popup from "./models/Popup";
import { observer } from "mobx-react";
import TourStore from "./stores/TourStore";
import { url } from "inspector";
import Image from "react-image";
import Popover, { ArrowContainer } from "react-tiny-popover";
import TourStop from "./models/TourStop";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import TourShape from "./models/Tour";
import $ from "jquery";

//polyfill
require("intersection-observer");

@observer
export default class Tour extends Component<{ tour: TourShape }, {}> {
  constructor(props) {
    super(props);
  }

  state = { tour: null, section: null, popup: null };

  private _selectSection: (
    sectionTitle: string
  ) => void = TourStore.selectSection.bind(TourStore);

  private _observer: IntersectionObserver = new IntersectionObserver(
    entries => {
      let selectedEntry;

      let sortedEntries = entries.sort((a, b) => {
        if (a.boundingClientRect.top > b.boundingClientRect.top) {
          return -1;
        } else {
          return 1;
        }
        return 0;
      });

      for (let i = 0; i < sortedEntries.length; i++) {
        let entry = entries[i];
        if (entry.boundingClientRect.top > 0) {
          selectedEntry = entry;
          continue;
        }
      }
      if (selectedEntry) {
        let sec = TourStore.tour.sections.find(
          s => s.title.replace(" ", "_") == selectedEntry.target.id
        );

        if (sec && TourStore.section && sec.title != TourStore.section.title) {
          console.log("selecting section", sec.title);
          //this._selectSection(sec.title);
        }
      }
    }
  );

  setUpObservers() {
    this._observer.disconnect();
    TourStore.tour.sections.forEach(section => {
      let elem = ReactDOM.findDOMNode(this.refs[section.title]);
      if (elem) this._observer.observe(elem as HTMLElement);
    });
  }

  componentDidMount() {
    if (TourStore.popups) TourStore.selectPopup(TourStore.popups[0]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tour && prevProps.tour.title != this.props.tour.title) {
      this.setUpObservers();
    }
  }

  componentWillMount() {
    this._observer.disconnect();
  }

  render() {
    const {
      tour,
      section,
      popup,
      popups,
      selectPopup,
      unSelectPopup,
      setPopupIdxForStop,
      changeStopIdx
    } = TourStore;

    const _changeStopIdx: (
      stop: TourStop,
      forwardOrBack: 1 | -1
    ) => void = changeStopIdx.bind(TourStore);
    const _selectPopup = selectPopup.bind(TourStore);
    const _unselectPopup = unSelectPopup.bind(TourStore);
    const _setPopupIdxForStop: (
      stop: TourStop,
      popup: Popup
    ) => void = setPopupIdxForStop.bind(TourStore);

    const getStopByPopup = (popup: Popup) => {
      for (let i = 0; i < tour.sections.length; i++) {
        let sec = tour.sections[i];
        for (let j = 0; j < sec.stops.length; j++) {
          let stop = sec.stops[j];
          if (stop.popups.some(p => p.content == popup.content)) return stop;
        }
      }
    };

    const handlePopupChange = (
      current: number,
      advanceOrGoBack: -1 | 1 | number = 1,
      overrideIdx: boolean = false,
      noScroll: boolean = false
    ) => {
      let targetIdx = overrideIdx ? current : current + advanceOrGoBack;
      let nextPopup = popups[targetIdx];

      //console.log(nextPopup, targetIdx);

      let nextStop = getStopByPopup(nextPopup);
      if (nextStop && !noScroll) {
        _setPopupIdxForStop(nextStop as TourStop, nextPopup);
        let ref = this.refs[nextPopup.globalIdx.toString() + "_POPUP"];
        let element = (ReactDOM as any).findDOMNode(ref) as HTMLElement;
        if (element) {
          let targetElem = element.closest(".tour-stop");
          if (!targetElem) return;
          let target =
            targetElem.getBoundingClientRect().top -
            150 -
            document.body.getBoundingClientRect().top;

          console.log("TARGET ACQUIRED new", target, window.innerWidth);
          console.log(window.location)
          //stop_${s.title.replace(" ","_")
          window.location.hash = targetElem.id;
          console.log(window.location)
        }
      }

      _selectPopup(nextPopup);
    };

    const getMarkerPopupCSSClasses = (
      thisPopup: Popup,
      selectedPopup: Popup
    ): string => {
      let classes = `popup-marker ${thisPopup.position}`;
      if (thisPopup.globalIdx == selectedPopup.globalIdx) classes += " active";

      return classes;
    };

    return this.props.tour.sections.map(secs => (
      <section
        key={secs.title}
        ref={secs.title}
        className={`tour-section ${secs.isIntro ? " intro-section" : ""}`}
        id={secs.title.replace(" ", "_")}
      >
        <div className="inner-intro">
          <h1 className="section-title" id={secs.isIntro ? "main" : ""}>
            {secs.title}
          </h1>
          {secs.introContent && (
            <>
              <div dangerouslySetInnerHTML={{ __html: secs.introContent }} />
              <a
                onClick={e => {
                  handlePopupChange(0, 0, true);
                }}
                className="tour-starter"
              >
                <p>Start Tour</p>

                <svg id="more-arrows">
                  <polygon
                    className="arrow-top"
                    points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "
                  />
                  <polygon
                    className="arrow-middle"
                    points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "
                  />
                  <polygon
                    className="arrow-bottom"
                    points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1"
                  />
                </svg>
              </a>
            </>
          )}
        </div>

        {secs.stops.map(s => {
          if (secs.introContent) return null;
          return (
            <>
              <div
                className={`tour-stop grid ${s.title} ${
                  s.effectImages ? " cause-effect" : ""
                } ${
                  s.popups.every(s => !s.clientNeed || !s.clientNeed.length)
                    ? " no-cn"
                    : ""
                }`}
                id={`stop_${s.title.replace(" ","_")}`}
                ref={s.title}
              >
                {!isNaN(s.selectedPopupIdx) &&
                  s.popups[s.selectedPopupIdx] != undefined &&
                  s.popups[s.selectedPopupIdx].clientNeed && (
                    <div className="client-need">
                      {!isNaN(s.selectedPopupIdx) &&
                        s.popups[s.selectedPopupIdx] != undefined &&
                        s.popups[s.selectedPopupIdx].clientNeed}
                    </div>
                  )}
                <div className="tour-image">
                  <div className="full-tip-wrap">
                    {s.popups.map(p => (
                      <div
                        id={p.globalIdx.toString() + "_POPUP"}
                        className={`full-tip ${
                          popup && p.title == popup.title ? " active" : ""
                        }`}
                      >
                        <a
                          className={`popup-marker ${
                            popup && p.globalIdx == popup.globalIdx
                              ? " active"
                              : ""
                          }`}
                          onClick={() =>
                            handlePopupChange(p.globalIdx, 1, true, true)
                          }
                        >
                          {p.globalIdx + 1}
                        </a>
                        {p.clientNeed && <p>{p.clientNeed}</p>}
                        <p>
                          <span className="header">{p.title}</span> --{" "}
                          {p.content}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="inner">
                    {!s.video && <Image className="tour-image-img" src={s.image} />}

                    {s.causeImages && s.effectImages && (
                      <div className="cause-effect-wrapper">
                        <div
                          className="cause image-wrap"
                          style={{
                            backgroundImage: `url(${
                              s.causeImages[s.causeImageIdx]
                            })`
                          }}
                        >
                          <span
                            className="caption"
                            dangerouslySetInnerHTML={{
                              __html: s.causeCaptions[s.causeImageIdx]
                            }}
                          />
                          <div className="cause-effect-controls">
                            <button
                              disabled={s.causeImageIdx == 0}
                              className="paginate left"
                              onClick={e => _changeStopIdx(s, -1)}
                            >
                              <i />
                              <i />
                            </button>
                            <button
                              disabled={
                                s.causeImageIdx == s.causeImages.length - 1
                              }
                              className="paginate right"
                              onClick={e => _changeStopIdx(s, 1)}
                            >
                              <i />
                              <i />
                            </button>
                            <div className="try">
                              <div className="arrow" />
                              <p>Try Me</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="effect image-wrap"
                          style={{
                            backgroundImage: `url(${
                              s.effectImages[s.effectImageIdx]
                            })`
                          }}
                        >
                          <span
                            className="caption"
                            dangerouslySetInnerHTML={{
                              __html: s.effectCaptions[s.causeImageIdx]
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {s.video && s.video.length > 0 && (
                      <>
                      <video width="320" height="240" controls playsinline poster={s.image}>
                    
                        <source src={s.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      </>
                    )}

                    {s.popups.map((p, i) => (
                      <>
                        <Popover
                          ref={p.globalIdx.toString() + "_POPUP"}
                          isOpen={popup && p.globalIdx == popup.globalIdx}
                          position={p.positions}
                          padding={10}
                          disableReposition={true}
                          transitionDuration={0.003}
                          containerClassName={`react-tiny-popover-container ${p.positions.join(
                            " "
                          )}`}
                          content={({ position, targetRect, popoverRect }) => (
                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                              position={position}
                              targetRect={targetRect}
                              popoverRect={popoverRect}
                              arrowColor={"#566581"}
                              arrowSize={10}
                            >
                              <div
                                className={`popup-content`}
                                id={p.globalIdx.toString() + "_POPUP"}
                              >
                                <button
                                  className="close-me"
                                  onClick={e => _unselectPopup()}
                                >
                                  X
                                </button>
                                <h1>{p.title}</h1>
                                {p.content}
                                <div className="controls">
                                  <button
                                    className="paginate left"
                                    onClick={() =>
                                      handlePopupChange(p.globalIdx, -1)
                                    }
                                    disabled={p.globalIdx == 0}
                                  >
                                    <i />
                                    <i />
                                  </button>
                                  <span className="numbers">
                                    {p.globalIdx + 1} / {popups.length}
                                  </span>
                                  <button
                                    className="paginate right"
                                    onClick={() =>
                                      handlePopupChange(p.globalIdx, 1)
                                    }
                                    disabled={p.globalIdx == popups.length - 1}
                                  >
                                    <i />
                                    <i />
                                  </button>
                                </div>
                              </div>
                            </ArrowContainer>
                          )}
                        >
                          <a
                            className={getMarkerPopupCSSClasses(p, popup)}
                            style={{
                              top: p.target[0] + "%",
                              left: p.target[1] + "%"
                            }}
                            ref={p.title}
                            onClick={() =>
                              handlePopupChange(p.globalIdx, 1, true)
                            }
                          >
                            {p.globalIdx + 1}
                          </a>
                        </Popover>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </section>
    ));
  }
}
