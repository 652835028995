import TourSection from "./TourSection";
import BaseModel from "./BaseModel";

export default class Tour extends BaseModel{

    constructor(data?:Partial<Tour>){
        super(data);
        if(data){
            this.setUp(data)
        }
    }

    title: string = "";
    introContent: string = "";
    introImage: string = "";
    tagLine:string = "";
    tagImage: string = "";
    tagBgColor: string = "";

    sections: TourSection[] = [];
}