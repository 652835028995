import Popup from "./Popup";
import BaseModel from "./BaseModel";

export default class TourStop extends BaseModel{
    constructor(data?:Partial<TourStop>){
        super(data);
        if(data){
            this.setUp(data)
        }
    }

    image: string = "";
    video: string = "";
    title: string = "";
    popups: Popup[] = [];
    selectedPopupIdx: number = 0;

    //interactivity stuff
    causeImages?: string[];
    causeCaptions: string[] = [];
    effectImages?: string[];
    effectCaptions: string[] = [];
    causeImageIdx: number = 0;
    effectImageIdx: number = 0;

}