import TourStop from "./TourStop";
import BaseModel from "./BaseModel";

export default class TourSection extends BaseModel{


    constructor(data?: Partial<TourSection>){
        super(data);
        if(data){
            this.setUp(data)
        }
    }

    isIntro: boolean = false;
    introContent: string = "";
    stops: TourStop[] = [];
    title: string = "";
}