import {observable, action} from 'mobx'

export default class BaseModel {

    constructor(data?: any){}
    
    setUp(data){
        if(data){
            for(var prop in data){
                this[prop] = data[prop];
            }
        }
    }
    
}